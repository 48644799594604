<template>
  <div class='layout-header'>
    <div class='layout-header__left'>
      <router-link :to='{ name: "Dashboard" }'>
        <icon-logo />
      </router-link>
    </div>
    <div class='layout-header__right' v-if="this.$store.state.user.login">
      <div class='layout-header__right__id'>
        <p>会員番号: {{ $store.state.user.id }}</p>
      </div>
      <button @click='changeActive("dropdownUser", true)'>
        <p>{{ $store.state.user.profile.name }}</p>
        <i class='fas fa-chevron-down' v-if='!isActive.dropdownUser' />
        <i class='fas fa-chevron-up' v-if='isActive.dropdownUser' />
      </button>
    </div>
    <notifications group="top-right"/>
    <dropdown-user
      class='layout-header__dropdown'
      v-if='isActive.dropdownUser'
      @emitClickLogout='logout()'
    />
    <overlay-normal
      v-if='isActive.dropdownUser'
      @emitClickOverlay='changeActive("dropdownUser", false)'
    />
  </div>
</template>

<script>
import IconLogo from '@/components/atoms/icon/IconLogo.vue';
import DropdownUser from '@/components/atoms/other/DropdownUser.vue';
import OverlayNormal from '@/components/atoms/other/OverlayNormal.vue';

export default {
  components: {
    IconLogo,
    DropdownUser,
    OverlayNormal,
  },
  data() {
    return {
      isActive: {
        dropdownUser: false,
      },
    };
  },
  methods: {
    changeActive(target, bool) {
      this.isActive[target] = bool;
    },
    async logout() {
      const result = await this.$store.dispatch('execLogout');
      const routeName = await result === 401 || result === true ? 'Top' : 'Error';
      await this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss">
.layout-header {
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.layout-header__right {
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
    font-weight: 600;
    padding: 8px 16px;
    background-color: $color-yellow;
    border: 1px solid $color-dark-yellow;
    border-radius: 3px;
    display: flex;
    align-items: center;
    transition: 0.1s;
    color: $color-black;
    p {
      font-size: 12px;
      margin-right: 10px;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    i {
      font-size: 14px;
    }
  }
  button:hover {
    background-color: $color-dark-yellow;
  }
}

.layout-header__right__id {
  background-color: $color-white;
  border: 1px solid $color-gray;
  border-radius: 3px;
  padding: 8px 10px;
  margin-right: 15px;
  p {
    font-size: 12px;
    font-weight: 500;
  }
}

.layout-header__dropdown {
  position: absolute;
  top: 80px;
  right: 40px;
  z-index: 999;
}

.vue-notification {
  margin-top: 30px;
  margin-right: 30px;
}
</style>
