<template>
  <ul class='button-toggle'>
    <li v-for='(name, index) in nameList' :key='index'>
      <button
        :class='addClass(index)'
        :disabled="addDisabled(index)"
        @click='$emit("emitClickToggle", index)'
      >
        {{ name }}
      </button>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    select: Number,
    nameList: Array,
  },
  data() {
    return {
    };
  },
  methods: {
    addClass(index) {
      return {
        select: index === this.select,
      };
    },
    addDisabled(index) {
      return index === this.select;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-toggle {
  display: flex;
  margin: 0;
  padding: 5px;
  background-color: $color-gray;
  border-radius: 10px;
  li {
    width: 100%;
    button {
      width: 100%;
      text-align: center;
      padding: 5px 0;
      font-size: 14px;
      border-radius: 6px;
    }
  }
}

.select {
  background-color: $color-white;
  font-weight: 600;
}

button:disabled {
  color: inherit;
}
</style>
