<template>
  <div class='bot-question-reserve'>
    <div class='bot-question-reserve__main'>
      <div class='bot-question-reserve__main__info'>
        <div class='bot-question-reserve__main__info__top'>
          <h3>メンター質問予約</h3>
          <button @click='$emit("emitClose")'>
            ×
          </button>
        </div>
        <div class='bot-question-reserve__main__info__bottom'>
          <p>
            こちらからエンジニアメンターへの質問予約を取ること
            ができます。質問はビデオチャット(Meet)でお答えし
            30分ごとに質問チケットが1枚必要です。
            下のフォームからご予約をお願いします。
            (ご指名の方は別途指名チケットも必要です。)
          </p>
        </div>
      </div>
      <div class='bot-question-reserve__main__toggle'>
        <button-toggle
          @emitClickToggle='changeToggleSelect($event)'
          :select='toggleInfo.select'
          :nameList='toggleInfo.nameList'
        />
      </div>
      <div class='bot-question-reserve__main__not-appoint' v-show='toggleInfo.select === 0'>
        <ul class='bot-question-reserve__main__not-appoint__select'
          v-if='!selectedNotAppoint.status && !showNeedTicketNotAppoint'>
          <li v-for='(notAppointData, index) in setNotquestionReserveList' :key='index'>
            <button @click='setTargetNotAppointForm(index)'>
              <p>{{ notAppointData.name }}</p>
              <i class='fas fa-chevron-right' />
            </button>
          </li>
        </ul>
        <div class='bot-question-reserve__main__not-appoint__form'
          v-if='selectedNotAppoint.status && !showNeedTicketNotAppoint'>
          <button @click='deleteTargetNotAppointForm()'>
            <i class='fas fa-chevron-left' />
            <p>フォーム選択に戻る</p>
          </button>
          <iframe :src='setNotAppointFormUrl' />
        </div>
        <div
          class='bot-question-reserve__main__not-appoint__need-ticket'
          v-if='showNeedTicketNotAppoint'
        >
          <p>質問には「質問チケット」が必要です。</p>
          <button-normal>
            <router-link :to='{ name: "SettingTickets", params: { ticketName: "question" }}'>
              ご購入はこちらから
            </router-link>
          </button-normal>
        </div>
      </div>
      <div class='bot-question-reserve__main__appoint' v-show='toggleInfo.select === 1'>
        <ul class='bot-question-reserve__main__appoint__select'
          v-if='!showNeedTicketAppoint && !selectedAppoint.choiceLessonStatus'>
          <li v-for='(notAppointData, index) in setNotquestionReserveList' :key='index'>
            <button @click='choiceLesson(index)'>
              <p>{{ notAppointData.name }}</p>
              <i class='fas fa-chevron-right' />
            </button>
          </li>
        </ul>
        <button @click='backToChoiceLesson()'
          v-if='
            !selectedAppoint.choiceMentorStatus &&
            !showNeedTicketAppoint &&
            selectedAppoint.choiceLessonStatus'
          >
          <i class='fas fa-chevron-left' />
          <p>フォーム選択に戻る</p>
        </button>
        <ul class='bot-question-reserve__main__appoint__mentor'
          v-if='
          !selectedAppoint.choiceMentorStatus &&
          !showNeedTicketAppoint &&
          selectedAppoint.choiceLessonStatus'
        >
          <li v-for='(mentor, index) in setMentorList' :key='index'>
            <img :src='mentor.imageUrl'>
            <div>
              <h5>{{ mentor.name }}</h5>
              <p>{{ mentor.description }}</p>
            </div>
            <button-normal @emitClick='setTargetAppointForm(mentor.id)'>
              <p>予約する</p>
            </button-normal>
          </li>
        </ul>
        <div
          class='bot-question-reserve__main__appoint__form'
          v-if='selectedAppoint.choiceMentorStatus && !showNeedTicketAppoint'
        >
          <button @click='deleteTargetAppointForm()'>
            <i class='fas fa-chevron-left' />
            <p>メンター選択に戻る</p>
          </button>
          <iframe :src='setAppointFormUrl' />
        </div>
        <div class='bot-question-reserve__main__appoint__need-ticket' v-if='showNeedTicketAppoint'>
          <p>質問には「質問チケット」「指名チケット」<br>が必要です。</p>
          <button-normal>
            <router-link :to='{ name: "SettingTickets", params: { ticketName: "question" }}'>
              ご購入はこちらから
            </router-link>
          </button-normal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonToggle from '@/components/atoms/button/ButtonToggle.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';

export default {
  components: {
    ButtonToggle,
    ButtonNormal,
  },
  data() {
    return {
      toggleInfo: {
        select: 0,
        nameList: [
          '指名なし',
          '指名あり',
        ],
      },
      selectedNotAppoint: {
        status: false,
        formIndex: null,
      },
      selectedAppoint: {
        choiceLessonStatus: false,
        choiceMentorStatus: false,
        formIndex: null,
        mentorId: null,
      },
      questionReserveList: [
        {
          courseId: 1,
          formList: [
            {
              mentorIdList: [1, 2],
              name: 'Lesson1-6の質問',
              url: 'https://www.jicoo.com/event_types/UctRpaSa4NzA/widget',
            },
          ],
        },
        {
          courseId: 2,
          formList: [
            {
              mentorIdList: [1, 2],
              name: 'Lesson1-6の質問',
              url: 'https://www.jicoo.com/event_types/UctRpaSa4NzA/widget',
            },
            {
              mentorIdList: [1],
              name: 'Lesson7-9の質問',
              url: 'https://www.jicoo.com/event_types/zcCW3JY-H8vS/widget',
            },
          ],
        },
        {
          courseId: 3,
          formList: [
            {
              mentorIdList: [1, 2],
              name: 'Lesson1-6の質問',
              url: 'https://www.jicoo.com/event_types/UctRpaSa4NzA/widget',
            },
            {
              mentorIdList: [2],
              name: 'Lesson7-11の質問',
              url: 'https://www.jicoo.com/event_types/_x2PJF97c1ri/widget',
            },
          ],
        },
        {
          courseId: 4,
          formList: [
            {
              mentorIdList: [1, 2],
              name: 'Lesson1-6の質問',
              url: 'https://www.jicoo.com/event_types/UctRpaSa4NzA/widget',
            },
            {
              mentorIdList: [1],
              name: 'Lesson 7-9 質問',
              url: 'https://www.jicoo.com/event_types/R5tHVXZrLM-9/widget',
            },
            {
              mentorIdList: [2],
              name: 'Lesson 10-14 質問',
              url: 'https://www.jicoo.com/event_types/0mepWR74pqPg/widget',
            },
          ],
        },
      ],
      appointMentorList: [
        {
          id: 1,
          name: '原田メンター',
          description: 'フロントエンドのメンタリングを担当。ちょっとした事でもぜひ聞いてください！よろしくお願いします。',
          formUrl: 'https://www.jicoo.com/event_types/6TRV3gBzgpnO/widget',
          imageUrl: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/mentor/harada.jpg`,
        },
        {
          id: 2,
          name: '長江メンター',
          description: 'バックエンドのメンタリングを担当。異業種からエンジニアになり開発経験を積んできました。全力でサポートいたします！',
          formUrl: 'https://www.jicoo.com/event_types/dImN63LmN9aE/widget',
          imageUrl: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/mentor/nagae.jpg`,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('loadTicketInfo');
  },
  methods: {
    changeToggleSelect(index) {
      this.toggleInfo.select = index;
    },
    choiceLesson(index) {
      this.selectedAppoint.choiceLessonStatus = true;
      this.selectedAppoint.formIndex = index;
    },
    backToChoiceLesson() {
      this.selectedAppoint.choiceLessonStatus = false;
      this.selectedAppoint.formIndex = false;
    },
    setTargetAppointForm(mentorId) {
      this.selectedAppoint.choiceMentorStatus = true;
      this.selectedAppoint.mentorId = mentorId;
    },
    deleteTargetAppointForm() {
      this.selectedAppoint.choiceMentorStatus = false;
      this.selectedAppoint.mentorId = null;
    },
    setTargetNotAppointForm(index) {
      this.selectedNotAppoint.status = true;
      this.selectedNotAppoint.formIndex = index;
    },
    deleteTargetNotAppointForm() {
      this.selectedNotAppoint.status = false;
      this.selectedNotAppoint.formIndex = null;
    },
  },
  computed: {
    setMentorList() {
      const target = this.questionReserveList
        .filter((v) => v.courseId === this.$store.state.study.courseInfo.id)[0];
      const { mentorIdList } = target.formList[this.selectedAppoint.formIndex];
      return this.appointMentorList.filter((v) => mentorIdList.indexOf(v.id) !== -1);
    },
    setAppointFormUrl() {
      const mentor = this.setMentorList.find((v) => v.id === this.selectedAppoint.mentorId);
      return mentor.formUrl;
    },
    setNotquestionReserveList() {
      const target = this.questionReserveList
        .filter((v) => v.courseId === this.$store.state.study.courseInfo.id)[0];
      return target.formList;
    },
    setNotAppointFormUrl() {
      const target = this.questionReserveList
        .filter((v) => v.courseId === this.$store.state.study.courseInfo.id)[0];
      return target.formList[this.selectedNotAppoint.formIndex].url;
    },
    showNeedTicketNotAppoint() {
      const questionTicketInfo = this.$store.state.ticket.filter((v) => v.id === 1)[0];
      return questionTicketInfo.user_tickets_count < 1;
    },
    showNeedTicketAppoint() {
      const appointTicketInfo = this.$store.state.ticket.filter((v) => v.id === 2)[0];
      const questionTicketInfo = this.$store.state.ticket.filter((v) => v.id === 1)[0];
      return appointTicketInfo.user_tickets_count < 1
        || questionTicketInfo.user_tickets_count < 1;
    },
  },
};
</script>

<style lang="scss">
.bot-question-reserve {
  width: 400px;
}

.bot-question-reserve__main {
  padding: 20px;
  background-color: $color-white;
  border-radius: 20px;
  box-shadow:  0 10px 10px 4px rgba(0, 0, 0, 0.1);
  height: 80vh;
  max-height: 800px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.bot-question-reserve__main__info__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  h3 {
    font-size: 20px;
  }
  button {
    font-size: 24px;
  }
}

.bot-question-reserve__main__info__bottom {
  p {
    font-size: 12px;
    font-weight: 500;
  }
}

.bot-question-reserve__main__toggle {
  margin: 15px 0;
}

.bot-question-reserve__main__not-appoint__select, .bot-question-reserve__main__appoint__select{
  margin: 20px 0 0;
  li {
    margin-top: 15px;
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: 1.5px solid $color-yellow;
      border-radius: 6px;
      padding: 10px 15px;
      color: $color-black;
      transition: 0.1s;
      p {
        font-size: 16px;
      }
      i {
        font-size: 14px;
      }
    }
    button:hover {
      background-color: $color-yellow;
    }
  }
}

.bot-question-reserve__main__not-appoint__form {
  height: 550px;
  button {
    align-items: center;
    display: flex;
    color: $color-black;
    padding: 0 0 1px 0;
    p {
      font-size: 12px;
      margin-left: 4px;
    }
    i {
      font-size: 12px;
    }
  }
  button:hover {
    border-bottom: 1px solid $color-black;
    padding: 0;
  }
  iframe {
    border: 0;
    height: 670px;
    width: 117%;
    transform: scale(0.85);
    transform-origin: left top;
    display: block;
  }
}

.bot-question-reserve__main__not-appoint__need-ticket {
  text-align: center;
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.bot-question-reserve__main__appoint > button {
  display: flex;
  color: $color-black;
  padding: 0 0 1px 0;
  align-items: center;
  p {
    font-size: 12px;
    margin-left: 4px;
  }
  i {
    font-size: 12px;
  }
}

.bot-question-reserve__main__appoint > button:hover {
  border-bottom: 1px solid $color-black;
  padding: 0;
}

.bot-question-reserve__main__appoint__form {
  height: 550px;
  button {
    display: flex;
    align-items: center;
    color: $color-black;
    padding: 0 0 1px 0;
    p {
      font-size: 12px;
      margin-left: 4px;
    }
    i {
      font-size: 12px;
    }
  }
  button:hover {
    border-bottom: 1px solid $color-black;
    padding: 0;
  }
  iframe {
    border: 0;
    height: 670px;
    width: 117%;
    transform: scale(0.85);
    transform-origin: left top;
    overflow: scroll;
    display: block;
  }
}

.bot-question-reserve__main__appoint__need-ticket {
  text-align: center;
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.bot-question-reserve__main__appoint__mentor {
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
  li {
    align-items: center;
    display: flex;
    margin-top: 20px;
    margin-right: 20px;
    img {
      width: 70px;
      height: 70px;
      border-radius: 100px;
    }
    div {
      margin: 0 15px;
      h5 {
        font-size: 14px;
        font-weight: 600;
        @extend .strimwidth-one;
      }
      p {
        margin-top: 5px;
        font-size: 10px;
        @extend .strimwidth-three;
      }
    }
  }
}
</style>
