<template>
  <ul class='dropdown-user'>
    <li>
      <router-link :to='{ name: "SettingAccount" }'>
        設定
      </router-link>
    </li>
    <li>
      <button @click='$emit("emitClickLogout")'>
        ログアウト
      </button>
    </li>
  </ul>
</template>

<script>
export default {
};
</script>

<style lang="scss">
.dropdown-user {
  background-color: $color-white;
  position: relative;
  width: 150px;
  padding: 10px;
  box-shadow:  0 10px 10px 4px rgba(0, 0, 0, 0.01);
  li {
    list-style: none;
    display: flex;
    align-items: center;
    border-radius: 3px;
    a {
      color: $color-black;
      font-size: 12px;
      text-decoration: none;
      width: 100%;
      padding: 8px;
    }
    a:visited {
      color: $color-black;
    }
    button {
      font-size: 12px;
      width: 100%;
      padding: 8px;
      text-align: left;
    }
  }
  li:hover {
    background-color: $color-gray;
  }
}

.dropdown-user::after {
  content: "";
  position: absolute;
  right: 0;
  top: -12px;
  right: 15px;
  width: 0px;
  height: 0px;
  margin: auto;
  border-right: 10px solid transparent;
  border-bottom: 16px solid $color-white;
  border-left: 10px solid transparent;
}
</style>
